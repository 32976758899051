export function criteriaList(){
    const criteria =
        [
            {
                id: 1,
                title: 'Идея проекта',
                sections:
                    [
                        {
                            id: 1,
                            name: 'Новизна, уникальность идеи',
                            values: 10,
                            activeIndex: 0,
                        },
                        {
                            id: 2,
                            name: 'Ценность (социальная значимость или коммерческая перспективность)',
                            values: 10,
                            activeIndex: 0,
                        },
                        {
                            id: 3,
                            name: 'Глубина проработки (погружение Команды в отрасль, проблематику)',
                            values: 10,
                            activeIndex: 0,
                        },

                    ]
            },
            {
                id: 2,
                title: 'Действующий прототип Проекта',
                sections:
                    [
                        {
                            id: 4,
                            name: 'Дизайн',
                            values: 10,
                            activeIndex: 0,
                        },
                        {
                            id: 5,
                            name: 'Инновационность и сложность технического решения',
                            values: 10,
                            activeIndex: 0,
                        },
                        {
                            id: 6,
                            name: 'Функциональность',
                            values: 10,
                            activeIndex: 0,
                        },
                    ]

            },
            {
                id: 3,
                title: 'Столкновение Проекта с реальностью',
                sections:
                    [
                        {
                            id: 7,
                            name: 'Способность Проекта реально решать поставленные задачи',
                            values: 10,
                            activeIndex: 0,
                        },
                        {
                            id: 8,
                            name: 'Возможность реализовать решение в жизнь',
                            values: 10,
                            activeIndex: 0,
                        },
                    ]

            },
            {
                id: 4,
                title: 'Выступление Команды',
                sections:
                    [
                        {
                            id: 9,
                            name: 'Умение презентовать результаты своей работы, строить логичный, понятный и интересный рассказ для презентации результатов своей работы',
                            values: 10,
                            activeIndex: 0,
                        },

                    ]

            },
        ];
    return criteria;


}