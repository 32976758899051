<script setup>
import AdminMenu from "@/components/Admin/TheLeftMenu";
import AdminNav from "@/components/Admin/TheTopnav";
import AdminFooter from "@/components/Admin/TheFooter"
import {useStore} from "vuex";
import {computed} from "vue";

const store = useStore()
const isMenuOpen = computed( () => {return store.getters['menuModule/isOpen']})

</script>
<template>
  <div :class="['wrapper', isMenuOpen ? 'sidebar-open' : '']">
    <admin-nav></admin-nav>
    <admin-menu></admin-menu>
      <slot/>
    <admin-footer></admin-footer>
  </div>
</template>
<style scoped>
  .fade-block{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1050
  }
  .fade-hide{display:none;}
  .fade-show{display:block;}
</style>