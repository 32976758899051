import { createApp } from 'vue'
import App from './App.vue'
import router from "@/router";
import store from '@/store'
import { Icon } from '@iconify/vue';
// контейнер модального окна
import VModal from "@/components/Admin/UI/VModal";
// окно подтверждения
import VConfirmModal from "@/components/Forms/VConfirmModal";
// стили
import './vendor.css'

const app = createApp(App)
    app.use(router)
    app.use(store)
    // глобальные компоненты
    app.component('v-icon', Icon)
    app.component('v-modal', VModal)
    app.component('v-confirm', VConfirmModal)
    app.mount('#app')