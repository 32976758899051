<script setup>
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {computed} from "vue";
const store = useStore()
const router = useRouter()
const logout = () => {
  store.commit('authModule/logout')
  router.push( {name: 'login'} )
}
const user = computed( () => {return store.getters['authModule/user']})
</script>

<template>
  <aside class="main-sidebar sidebar-dark-primary elevation-2">
    <div class="sidebar">

      <nav class="mt-4" v-if="user">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">

          <li class="nav-item">
            <router-link :to="{name: 'teams'}" class="nav-link">
              <div class="d-flex">
                <v-icon class="nav-icon" icon="mdi:account-multiple"></v-icon>
                <p class="pl-2">Команды</p>
              </div>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link :to="{name: 'hackathon'}" class="nav-link">
              <div class="d-flex">
                <v-icon class="nav-icon" icon="mdi:information-outline"></v-icon>
                <p class="pl-2">О мероприятии</p>
              </div>
            </router-link>
          </li>


        </ul>

        <div class="user-panel p-1"></div>

        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <li class="nav-item mt-3">
            <a href class="nav-link" @click.prevent="logout">
              <div class="d-flex">
                <v-icon class="nav-icon" icon="mdi:exit-to-app"></v-icon>
                <p class="pl-2">Выход</p>
              </div>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </aside>
</template>

<style scoped>
.menu-header{
  margin:20px;
}
.is-disabled{
  cursor: not-allowed;
  opacity: 0.33;
}
</style>