<script setup>
  import {useField, useForm} from "vee-validate";
  import * as yup from "yup";
  import {computed, watch} from "vue";
  // для работы со стором (авторизация)
  import {useStore} from "vuex"
  import {useRouter} from "vue-router"

  const store = useStore();
  const router = useRouter();

  const COUNT_ATTEMPTS = 3;
  //const MIN_PASSWORD_VALUE = 8;
  const ALLOW_SUBMIT = 3000;

  // форма, пока поисходит отправка, кнопку отключаем
  // submitCount показывает сколько попыток отправки

  const { handleSubmit, isSubmitting, submitCount } = useForm();
  const { value:email, errorMessage:emailError, handleBlur:emailBlur } = useField(
      'email',
      yup
          .string()
          .trim()
          .required('Укажите значение')
          .email('Укажите корректный email')
  );
  const { value:password, errorMessage:passwordError, handleBlur:passwordBlur } = useField(
      'password',
      yup
          .string()
          .trim()
          .required('Укажите значение')
  );
  // много попыток входа
  const isManyAttempts = computed(() => submitCount.value > COUNT_ATTEMPTS )

  // ошибка авторизации
  const authError = computed( () => store.getters['authModule/authError'] )

  // если кнопка заблокирована из-за isManyAttempts, сделаем доступной через 3 с.
  watch(isManyAttempts, val => {
    if (val){
      setTimeout(() => submitCount.value = 0, ALLOW_SUBMIT)
    }
  })

  const onSubmit = handleSubmit(async data => {
    //console.log(data)
    await store.dispatch('authModule/loginEmail', data)
    if (authError.value !== 'ERROR'){
      await router.push('/teams')
    }
  })
</script>
<template>
  <form @submit="onSubmit">
    <div class="input-group mt-2">
      <input
        type="text"
          :class="['form-control form-control-border input-label', {'is-invalid': emailError}]"
          placeholder="Email"
          v-model="email"
          @blur="emailBlur"
      >
    </div>
    <div
        class="input-error"
        v-if="emailError"
    >{{ emailError }}</div>
    <br>
    <div class="input-group mt-3">
      <input
          type="password"
          :class="['form-control form-control-border input-label', {'is-invalid': passwordError}]"
          placeholder="Пароль"
          v-model="password"
          @blur="passwordBlur"
      >
    </div>
    <div
        class="input-error"
        v-if="passwordError"
    >{{ passwordError }}</div>
    <div class="row mt-5 mb-2">
      <div class="col-12">
        <button
            type="submit"
            class="btn btn-primary btn-block mt-1"
            :disabled="isSubmitting || isManyAttempts"
        >
          <svg v-if="isSubmitting"
               xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g>
            <circle cx="3" cy="12" r="2" fill="#fff"/>
            <circle cx="21" cy="12" r="2" fill="#fff"/>
            <circle cx="12" cy="21" r="2" fill="#fff"/>
            <circle cx="12" cy="3" r="2" fill="#fff"/>
            <circle cx="5.64" cy="5.64" r="2" fill="#fff"/>
            <circle cx="18.36" cy="18.36" r="2" fill="#fff"/>
            <circle cx="5.64" cy="18.36" r="2" fill="#fff"/>
            <circle cx="18.36" cy="5.64" r="2" fill="#fff"/>
            <animateTransform attributeName="transform" dur="1.5s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12"/></g>
          </svg>
          <span v-else>Войти</span>
        </button>
      </div>
    </div>
  </form>

  <div
      v-if="authError === 'ERROR'"
      class="mt-3 text-center text-danger"
  >
    Ошибка авторизации
  </div>
</template>
<style scoped>
.input-error{position:absolute;color:#dc3545;padding:3px}
</style>