import { createStore } from 'vuex'
import authModule from "@/store/modules/auth.module";
import menuModule from "@/store/modules/menu.module";
import teamsModule from "@/store/modules/teams.module";
import criteriaModule from "@/store/modules/criteria.module";
import userModule from "@/store/modules/user.module";
export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    authModule,  menuModule, teamsModule, criteriaModule, userModule
  }
})
