<script setup>
import AdminLayout from "@/components/LayOuts/AdminLayout";
import LoginLayout from "@/components/LayOuts/LoginLayout";
import {useRoute} from "vue-router";
import {computed} from "vue";
const route = useRoute()
// eslint-disable-next-line vue/return-in-computed-property
const layout = computed( () => {
  if (route.meta.layout == 'admin') {
    return AdminLayout
  } else {
    return LoginLayout
  }
})
</script>
<template>
  <component
      v-if="layout"
      :is="layout"
  >
    <router-view></router-view>
  </component>
</template>
<style>
.nav-item .active{background-color:#494e53 !important;}
.nav-icon{width:20px;height:20px;margin:0}
.btn-icon{
  width:22px;
  height:22px;
}
.btn-icon--big{
  width:30px;
  height:30px;
}
.btn-link{
  display: -webkit-flex;
  display: flex;
  justify-content:flex-start;
  align-items: center;
  padding:10px;
  color:#f33c38 !important;
  cursor: pointer;
}
.btn-label{
  padding:0 0 0 10px;
}

.default-icon{
  width:28px;
  height:28px;
  color: #484848;
}
.info-icon{
  width:22px;
  height:22px;
  color: #ff2d2d;
}
.action-icon--gray{
  width:22px;
  height:22px;
  color: #b4b4b4;
  cursor: pointer;
}
.action-icon--black{
  width:22px;
  height:22px;
  color: #000;
  cursor: pointer;
}
.action-icon--light{
  width:22px;
  height:22px;
  color: #fff;
  cursor: pointer;
}
.success-icon--white{
  width:40px;
  height:40px;
  color: #fff;
}
.error-icon--red{
  width:90px;
  height:90px;
  color: #f33c38;
}
a.tooltip-custom-link span {
  display: none;
  position: absolute;
  background: #fffecc;
  font-size: 15px;
  border: 1px solid #cccccc;
  color: #6c6c6c;
  padding: 3px;
  margin: -55px 0 0 -45px;
  width: 140px;
  text-align:center;
  z-index: 9999;
}
a.tooltip-custom-link:hover span {
  display: inline;
}
.dark-background{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1050
}
.toggle-menu{
  display: none;
}

@media only screen and (max-width : 760px) {
  .toggle-menu{
    display: block;
  }
}

.btn{min-height: 40px !important;}

.btn-send{
  font-size: 24px;
  background-color: #80b5d3 !important;
  color:#fff !important;
  padding:15px 0 !important;
  transition: 0.3s;
  width:300px;
  height:60px;
}

.btn-send:hover{
  background-color: #69a7ca !important;
}

.ctrl-link{
  color:#000;
  cursor: pointer;
}

.ctrl-link-selected{
  border-bottom: 1px solid #797979;
}

.card-info.card-outline{
  border-top: 3px solid #80b5d3 !important;
}

a.black-link:link    {color:#000; text-decoration:none; }
a.black-link:active  {color:#000; text-decoration:none; }
a.black-link:visited {color:#000; text-decoration:none; }
a.black-link:hover   {color:#7e7e7e; text-decoration:none; }

a.card-link:link    {color:#000; text-decoration:none; }
a.card-link:active  {color:#000; text-decoration:none; }
a.card-link:visited {color:#000; text-decoration:none; }
a.card-link:hover   {color:#797979; text-decoration:none;
}

:-webkit-input-placeholder  {color:#91918e;}
::-moz-placeholder          {color:#91918e;}
:-moz-placeholder           {color:#91918e;}
:-ms-input-placeholder      {color:#91918e;}

input, textarea { outline:none; }
input:active, textarea:active { outline:none; }
:focus { outline:none; }

.number{
  display: flex;
  align-items:center;
  justify-content:center;
  text-align:center;
  width:50px;
  height:50px;
  border:3px solid #80b5d3;
  border-radius:50%;
  margin: 0 auto;
  color: #80b5d3;
  font-size: 22px;
  font-weight: bold;
}
.v-line{
  width:3px;
  height:40px;
  background-color: #80b5d3;
  margin: 0 auto;
}

</style>
