//import axios from "axios"
//import store from "@/store";

export default {
    // чтобы названия были локальные
    namespaced: true,

    state: {
        isOpen: false,
    },
    getters: {
        isOpen(state){
            return state.isOpen
        },
    },
    mutations: {
        toggleMenu(state){
            state.isOpen = !state.isOpen
        },
        initMenu(state){
            state.isOpen = false
        },
    },
}