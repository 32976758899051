//структура списка документов для загрузки

export default {
    // чтобы названия были локальные
    namespaced: true,

    state: {
        teams: [],
    },
    getters: {
        teams(state){
            return state.teams
        },
    },
    mutations: {
        setTeams(state, data){
            state.teams = data
        },
    },
    actions: {
    },
}