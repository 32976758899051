<script setup>
import {useStore} from "vuex";
import {computed, onMounted} from "vue";
const store = useStore()

onMounted( ()=>{
  init()
})

const init = async () => {
  store.commit('menuModule/initMenu')
}
const user = computed( () => {return store.getters['authModule/user']})
</script>

<template>
  <main class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col"><h3>Мой профиль</h3></div>
        </div>
      </div>
    </section>
    <section class="content" v-if="user != null">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <h5>{{ user['name'] }}</h5>
            <p>E-mail: {{ user['email'] }}</p>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<style scoped>
</style>