export default {
    // чтобы названия были локальные
    namespaced: true,

    state: {
        userData: [],
    },
    getters: {
        userData(state){
            return state.userData
        },
    },
    mutations: {
        setUserData(state, data){
            state.userData = data
        },
    },
    actions: {
    },
}