import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import TheLogin from "@/components/Common/TheLogin";
import TheProfile from "@/components/Admin/TheProfile";
import TheTeams from "@/components/Admin/TheTeams";
import TheTeam from "@/components/Admin/TheTeam";
import TheHackathon from "@/components/Admin/TheHackathon";

const router =  createRouter ({
    history: createWebHistory(),
    routes:[

        {
            path: '/',
            component: TheLogin,
            meta: {
                title: 'Авторизация',
                layout: 'login',
                auth: false
            },
            name: 'login',
        },

        // закрытые маршруты
        {
            path: '/profile',
            component: TheProfile,
            meta: {
                title: "Профиль",
                layout: 'admin',
                auth: true
            },
            name: 'profile',
        },

        {
            path: '/teams',
            component: TheTeams,
            meta: {
                title: "Команды",
                layout: 'admin',
                auth: true
            },
            name: 'teams',
        },

        {
            path: '/hackathon',
            component: TheHackathon,
            meta: {
                title: "О мероприятии",
                layout: 'admin',
                auth: true
            },
            name: 'hackathon',
        },

        {
            path: '/team/:id',
            component: TheTeam,
            meta: {
                title: "Команда",
                layout: 'admin',
                auth: true
            },
            name: 'team',
        },

        {
            path: '/:notFound(.*)', redirect: '/'
        },
    ],

    // переопределить классы активных ссылок
    linkActiveClass: 'active',
    linkExactActiveClass: 'active'
})


router.beforeEach((to, from, next) =>{
    document.title = to.meta.title
    const requireAuth = to.meta.auth
    let toDefaultRoute = false

    // если авторизован, но на страницу входа не попасть
    if (to.path === '/' && store.getters['authModule/isAuth']){
        toDefaultRoute = true
    }

    /*

    if (store.getters['authModule/user'] && Number(store.getters['authModule/user'].role) !== 100) {
        if (to.path === '/juri' || to.path === '/juri/') {
            toDefaultRoute = true
        }
    }

     */

    if (!requireAuth || (requireAuth && store.getters['authModule/isAuth'])){

        if (toDefaultRoute){
            next('/teams')
        } else {
            next()
        }

    } else if (requireAuth && !store.getters['authModule/isAuth']){
        // редирект неавторизованных пользователей на '/'
        next('/')
    }
});

export default router;