// Аутентификация – процедура проверки подлинности, например,
// проверка подлинности пользователя путем сравнения
// введенного им пароля с паролем, сохраненным в базе данных.

const JWT_TOKEN = 'jwt_token'
import axios from "axios";
//import store from "@/store";

export default {
    // чтобы названия были локальные
    namespaced: true,

    state: {
        token: localStorage.getItem(JWT_TOKEN),
        authError: null
    },
    getters: {
        token(state){
            return state.token
        },
        authError(state){
            return state.authError
        },
        // используется в роутере
        isAuth(state){
            return !!state.token
        },
        // передаем id из токена
        userId(state){
            if (state.token != null) {
                // декодируем первую часть токена (до точки)
                let data = JSON.parse(atob(state.token.slice(0, state.token.indexOf('.'))))
                return data.id
            } else {
                return null
            }
        },
        // передаем userName из токена
        // в этой версии не используем, потому что каждый раз обновляем
        user(state){
            if (state.token != null) {
                // декодируем первую часть токена (до точки)
                return JSON.parse(atob(state.token.slice(0, state.token.indexOf('.'))))
            } else {
                return null
            }
        },
    },
    mutations: {
        setToken(state, token){

            //console.log(token)

            if (token === 'AUTH_ERROR'){
                state.authError = 'ERROR'
                state.token = null
                localStorage.removeItem(JWT_TOKEN)
            } else {
                state.authError = null
                state.token = token
                localStorage.setItem(JWT_TOKEN, token)
            }
        },
        logout(state){
            state.token = null
            localStorage.removeItem(JWT_TOKEN)
        },
    },
    actions: {

        async loginEmail( {commit}, payload ){
            await axios
                .post(process.env.VUE_APP_AUTH, payload)
                .then(response => (
                    commit('setToken', response.data)
                ))
                .catch(error => alert(error));
        }

    },
}