<script setup>
import {computed, onMounted, ref} from "vue";
import axios from "axios";
import {useStore} from "vuex";
const store = useStore()
// запрос на role делаем каждый раз, не только при авторизации
const userData = ref([])

onMounted( ()=>{
  try {
    init()
  } catch (err) {
    alert ('APPLICATION ERROR')
  }
})

const init = async () => {
  const urlData =  process.env.VUE_APP_ADMIN_URL + "/api/userData.php"

  // userId.value получен при авторизации
  await axios
      .get(`${urlData}?token=${token.value}&id=${userId.value}`)
      .then(response => (userData.value = response.data))

  // обновить данные о пользователе
  store.commit('userModule/setUserData', userData.value)
  store.commit('menuModule/initMenu')
}

//----------------------------------------------------------------------
const token = computed( () => {return store.getters['authModule/token']})
const userId = computed( () => {return store.getters['authModule/userId']})

</script>
<template>
  <main class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col"><h3>О мероприятии</h3></div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">

            <div class="card card-outline card-info">
              <div class="card-header pt-3">
                <h4>Хакатон Smolathon</h4>
              </div>
              <div class="card-body">

                С 10 по 11 октября состоится хакатон Смолатон - молодежный турнир, соревнование молодых, амбициозных и проактивных. Это отличное место для людей с теоретическими знаниями, желающими получить реальный опыт.
                <br><br>
                <b>Формат хакатона:</b> очный.
                <br><br><b>Место проведения:</b> г. Смоленск (Энергетический пр., 1, филиал федерального государственного бюджетного образовательного учреждения высшего образования «Национальный исследовательский университет» «МЭИ» (ФГБОУ ВО «НИУ «МЭИ»); г. Смоленск, ул. Черняховского, 29, дворец спорта «Юбилейный»).
                <br><br><b>Участники соревнования:</b> участниками соревнований могут стать лица в возрасте от 14 до 35 лет.
                <br><br><b>Задача:</b> Разработка платформы и/или онлайн-инструмента для продвижения цифрового искусства и креативной экономики в Смоленске.
                <br><br><b>Номинации:</b><br><br>
                <ul>
                  <li>1С (Стэк: backend на 1С)</li>
                  <li>Сбер (Стэк: без ограничений)</li>
                </ul>
                <hr>

              </div>
            </div>


            <div class="card card-outline card-success">
              <div class="card-header pt-3">
                <h4>Организаторы</h4>
              </div>
              <div class="card-body">
                <div class="row">

                    <div class="col-md-3 col-sm-12 mb-4 text-center">
                      <img src="https://%D0%BB%D0%BA.%D1%81%D0%B2%D0%BE%D0%B9%D0%BA%D0%BE%D0%B4.%D1%80%D1%84/images/logo/mincifri.png" class="img-fluid">
                      <p class="mt-3">Минцифры<br>России</p>
                    </div>
                    <div class="col-md-3 col-sm-12 mb-4 text-center">
                      <img src="https://%D0%BB%D0%BA.%D1%81%D0%B2%D0%BE%D0%B9%D0%BA%D0%BE%D0%B4.%D1%80%D1%84/images/logo/asi.png" class="img-fluid">
                      <p class="mt-3">Агентство<br>стратегических инициатив</p>
                    </div>
                    <div class="col-md-3 col-sm-12 mb-4 text-center">
                      <img src="https://%D0%BB%D0%BA.%D1%81%D0%B2%D0%BE%D0%B9%D0%BA%D0%BE%D0%B4.%D1%80%D1%84/images/logo/pravitelstvo-smol.png" class="img-fluid">
                      <p class="mt-3">Правительство<br>Смоленской области</p>
                    </div>
                    <div class="col-md-3 col-sm-12 mb-4 text-center">
                      <img src="https://%D0%BB%D0%BA.%D1%81%D0%B2%D0%BE%D0%B9%D0%BA%D0%BE%D0%B4.%D1%80%D1%84/images/logo/ano.png" class="img-fluid">
                      <p class="mt-3">АНО «Лаборатория Цифровой<br>Трансформации»</p>
                    </div>
                  </div>

              </div>
            </div>

            <div class="card card-outline card-danger">
              <div class="card-header pt-3">
                <h4>Партнеры</h4>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-3 col-sm-12 mb-4 text-center">
                    <img src="https://%D0%BB%D0%BA.%D1%81%D0%B2%D0%BE%D0%B9%D0%BA%D0%BE%D0%B4.%D1%80%D1%84/images/logo/sber.png" class="img-fluid">
                    <p class="mt-3">Сбер</p>
                  </div>
                  <div class="col-md-3 col-sm-12 mb-4 text-center">
                    <img src="https://%D0%BB%D0%BA.%D1%81%D0%B2%D0%BE%D0%B9%D0%BA%D0%BE%D0%B4.%D1%80%D1%84/images/logo/1c.png" class="img-fluid">
                    <p class="mt-3">1С</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>

  </main>
</template>

<style scoped>
.logo{
  max-width: 350px;
  max-height: 200px;
  -webkit-filter: saturate(0%);
  filter: saturate(0%);
}
</style>